import { Component, OnInit, ContentChild, ViewChild, ViewChildren } from "@angular/core";
import { Location } from "@angular/common";
import { ActivatedRoute } from "@angular/router";
import { GoogleTranslateService } from "src/app/services/google-translate/google-translate.service";
import { TranslateService } from "@ngx-translate/core";
import { ModalController } from "@ionic/angular";
import { ChangeLanguagePage } from "../change-language/change-language.page";

@Component({
  selector: "app-privacy-policy",
  templateUrl: "./privacy-policy.page.html",
  styleUrls: ["./privacy-policy.page.scss"],
})
export class PrivacyPolicyPage implements OnInit {
  color: string;
  language: string;

  languageMap = [
    { key: "us", iso: "en" },
    { key: "de", iso: "de" },
    { key: "fr", iso: "fr" },
    { key: "es", iso: "es" },
    { key: "it", iso: "it" },
    { key: "jp", iso: "ja" },
    { key: "kr", iso: "ko" },
    { key: "pl", iso: "pl" },
    { key: "ru", iso: "ru" },
    { key: "se", iso: "sv" },
  ];
  constructor(
    private location: Location,
    private route: ActivatedRoute,
    private translateService: TranslateService,
    private modalCtrl: ModalController
  ) {}

  async ngOnInit() {
    this.route.params.subscribe((data) => {
      this.color = data.color;
      this.language = data.language;
    });
  }

  async openChangeLanguage() {
    const modal = await this.modalCtrl.create({
      component: ChangeLanguagePage,
      animated: true,
      componentProps: {
        language: this.translateService.currentLang,
      },
    });

    await modal.present();
  }

  async back() {
    await this.location.back();
  }
}
