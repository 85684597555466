import {
  Component,
  OnInit
} from '@angular/core';
import {
  ActivatedRoute
} from '@angular/router';
import {
  AngularFireAuth
} from '@angular/fire/auth';
import {
  ToastController
} from '@ionic/angular';
import {
  AlertController
} from '@ionic/angular';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.page.html',
  styleUrls: ['./reset-password.page.scss']
})
export class ResetPasswordPage implements OnInit {
  constructor(private route: ActivatedRoute, private fireauth: AngularFireAuth, public toastController: ToastController, public alertController: AlertController) {}
  email: string;
  urlParams: any;

  async ngOnInit() {
    this.route.queryParams.subscribe(async params => {
      this.urlParams = params;
      if (params.oobCode) {
        this.fireauth.verifyPasswordResetCode(params.oobCode).then(email => {
          this.email = email;
        }).catch(async error => {
          console.log(error);
          const alert = await this.alertController.create({
            header: 'Fehlerhafter Link',
            message: error.message,
            buttons: ['OK']
          });

          await alert.present();
        });
      }
    });
  }

  async resetPassword(form: any) {
    this.fireauth.confirmPasswordReset(this.urlParams.oobCode, form.value.password).then(async value => {
      console.log(value);
      const toast = await this.toastController.create({
        message: 'Dein Passwort wurde erfolgreich zurückgesetzt',
        duration: 6000,
      });
      toast.present();
      this.email = '';
    }).catch(async error => {
      console.log(error);
      const alert = await this.alertController.create({
        header: 'Fehlerhafter Link',
        message: error.message,
        buttons: ['OK']
      });

      await alert.present();
    });
  }

  ionViewDidEnter() {}
}