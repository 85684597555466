import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";

const routes: Routes = [
  {
    path: "",
    redirectTo: "home",
    pathMatch: "full",
  },
  {
    path: "",
    children: [
      {
        path: "",
        loadChildren: () => import("./pages/home/home.module").then((m) => m.HomePageModule),
      },
      {
        path: "privacy_policy",
        loadChildren: () =>
          import("./pages/privacy-policy/privacy-policy.module").then((m) => m.PrivacyPolicyPageModule),
      },
      {
        path: "privacy_policy/:color",
        loadChildren: () =>
          import("./pages/privacy-policy/privacy-policy.module").then((m) => m.PrivacyPolicyPageModule),
      },
      {
        path: "terms_of_use",
        loadChildren: () =>
          import("./pages/general-terms-and-conditions/general-terms-and-conditions.module").then(
            (m) => m.GeneralTermsAndCondictionsPageModule
          ),
      },
      {
        path: "terms_of_use/:color",
        loadChildren: () =>
          import("./pages/general-terms-and-conditions/general-terms-and-conditions.module").then(
            (m) => m.GeneralTermsAndCondictionsPageModule
          ),
      },
      {
        path: "eula",
        loadChildren: () => import("./pages/eula/eula.module").then((m) => m.EulaPageModule),
      },
      {
        path: "eula/:color",
        loadChildren: () => import("./pages/eula/eula.module").then((m) => m.EulaPageModule),
      },
    ],
  },
  {
    path: "reset-password",
    loadChildren: () => import("./pages/reset-password/reset-password.module").then((m) => m.ResetPasswordModule),
  },
  {
    path: "imprint",
    loadChildren: () => import("./pages/imprint/imprint.module").then((m) => m.ImprintPageModule),
  },
  { path: "**", loadChildren: () => import("./pages/home/home.module").then((m) => m.HomePageModule) },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
