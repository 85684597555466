import { Location } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { ChangeLanguagePage } from "../change-language/change-language.page";
import { ModalController } from "@ionic/angular";

@Component({
  selector: "app-general-terms-and-conditions",
  templateUrl: "./general-terms-and-conditions.page.html",
  styleUrls: ["./general-terms-and-conditions.page.scss"],
})
export class GeneralTermsAndCondictionsPage implements OnInit {
  color: string;
  constructor(
    private location: Location,
    private modalCtrl: ModalController,
    private route: ActivatedRoute,
    public translateService: TranslateService
  ) {}

  ngOnInit() {
    this.route.params.subscribe((data) => {
      this.color = data.color;
    });
  }

  ionViewDidEnter() {}
  async back() {
    await this.location.back();
  }
  async openChangeLanguage() {
    const modal = await this.modalCtrl.create({
      component: ChangeLanguagePage,
      animated: true,
      componentProps: {
        language: this.translateService.currentLang,
      },
    });

    await modal.present();
  }
}
