import { Component, OnInit } from "@angular/core";
import { ModalController, NavParams } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-change-language",
  templateUrl: "./change-language.page.html",
  styleUrls: ["./change-language.page.scss"],
})
export class ChangeLanguagePage implements OnInit {
  language: string;
  languages: Object;
  constructor(
    private navParams: NavParams,
    private modalCtrl: ModalController,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.language = this.navParams.get("language");
    this.languages = [
      { key: "de", value: "Deutsch" },
      { key: "us", value: "English" },
      { key: "fr", value: "Français" },
      { key: "es", value: "Espanol" },
      { key: "it", value: "Italiano" },
      { key: "jp", value: "日本人" },
      { key: "kr", value: "한국어" },
      { key: "pl", value: "Polski" },
      { key: "ru", value: "Pусский" },
      { key: "se", value: "Svenska" },
    ];
  }
  async changeLanguage() {
    try {
      await this.translateService.use(this.language);
    } catch (error) {
      console.log(error);
    }
  }
  async dismiss() {
    await this.modalCtrl.dismiss();
  }
}
