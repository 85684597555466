import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-home",
  templateUrl: "./home.page.html",
  styleUrls: ["./home.page.scss"],
})
export class HomePage implements OnInit {
  constructor(private router: Router) {}

  ngOnInit() {}

  async openPrivacyPolicy() {
    await this.router.navigateByUrl("/privacy_policy/sand");
  }
  async openTermsOfUse() {
    await this.router.navigateByUrl("/terms_of_use/sand");
  }
  async openEula() {
    await this.router.navigateByUrl("/eula/sand");
  }
  async openImprint() {
    await this.router.navigateByUrl("/imprint");
  }
}
